import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { all, AllEffect, call, CallEffect, put, PutEffect, takeLatest } from 'redux-saga/effects';
import { GetCostBreakdownRequestModel, GetQuotationRequestModel, GetQuotationRequestResponseModel, QuoteAcceptanceRequestPayload, RequestQuotationPayload } from '../../../services/quote.interfaces';
import { getCostBreakdown, getQuotation, postQuoteAcceptance, requestQuotation } from '../../../services/quote.service';
import { navigateTo } from '../../actions/navigate.action';
import { setIsLoading } from '../../slices/loading.store';
import { getCostBreakdownSuccess, getQuoteSuccess, requestQuoteSuccess, getQuote as getQuoteAction } from '../../slices/quote/quote.store';
import Cookies from 'js-cookie';
import extractQuoteCodeFromReference from '../../../utils/extractQuoteCodeFromReference/extractQuoteCodeFromReference';

const isAxiosError = (x: any): x is AxiosError => {
  return x.isAxiosError === true;
};

export function* getCostBreakdownHandlerSaga(
  action: PayloadAction<GetCostBreakdownRequestModel>,
): Generator<
  any,
  void,
  any
> {
  try {
    const { quoteReference } = action.payload;
    const trackingRef = action.payload.trackingRef || '';

    if (!quoteReference) throw new Error('quoteReference is not set');
    if (!trackingRef) throw new Error('trackingRef is not set');

    const getCostBreakdownRequest = yield call(() => getCostBreakdown(quoteReference, trackingRef));
    const { data: costBreakdownResponse } = getCostBreakdownRequest;

    if (extractQuoteCodeFromReference(quoteReference)) {
      Cookies.set('previousQuotationReference', String(extractQuoteCodeFromReference(quoteReference)), { expires: 7 });
    }

    yield all([
      put(getCostBreakdownSuccess(costBreakdownResponse)),
      put(getQuoteAction({ quoteReference, trackingRef }))
    ]);
  } catch (error: unknown) {
    yield all([put(setIsLoading(false))]);

    if (!isAxiosError(error)) return;

    // TODO - error needs to be implemented here
    // yield all([put(setError(error))]);
  }
}

export function* getQuote(
  action: PayloadAction<GetQuotationRequestModel>,
): Generator<
  CallEffect<AxiosResponse<GetQuotationRequestResponseModel>>
  | AllEffect<PutEffect<{ payload: GetQuotationRequestResponseModel; type: "quote/getQuoteSuccess"; }>
  | PutEffect<{ payload: boolean; type: "loading/setIsLoading"; }>>,
  void,
  any
> {
  try {
    const { quoteReference, trackingRef } = action.payload;

    if (!quoteReference) throw new Error('quoteReference is not set');
    if (!trackingRef) throw new Error('trackingRef is not set');
    
    const getQuoteRequest = yield call(() => getQuotation(quoteReference, trackingRef));
    const { data: quoteResponse } = getQuoteRequest;

    yield all([
      put(getQuoteSuccess(quoteResponse)),
      put(setIsLoading(false))      
    ]);
  } catch (error: unknown) {
    yield all([put(setIsLoading(false))]);

    if (!isAxiosError(error)) return;

    // TODO - error needs to be implemented here
    // yield all([put(setError(error))]);
  }
}

export function* requestQuote(
  action: PayloadAction<RequestQuotationPayload>,
): Generator<
  any,
  void,
  any
> {
  try {
    yield put(setIsLoading(true));
    const getQuoteRequest = yield call(() => requestQuotation(action.payload));
    const { data: quoteResponse } = getQuoteRequest;

    yield all([
      put(requestQuoteSuccess(quoteResponse))   
    ]);

    yield put(navigateTo(`/instant-quote/quote-stage-two?quoteReference=${quoteResponse.quoteReference}&trackingRef=${quoteResponse.trackingRef}`));
  } catch (error: unknown) {
    yield all([put(setIsLoading(false))]);

    if (!isAxiosError(error)) return;
    // TODO - error needs to be implemented here
    // yield all([put(setError(error))]);
  }
}

export function* acceptQuote(
  action: PayloadAction<{ quoteReferenceDetails: GetQuotationRequestModel, quoteAcceptanceRequest: QuoteAcceptanceRequestPayload}>,
): Generator<
  any,
  void,
  any
> {
  try {
    yield put(setIsLoading(true));

    const { quoteReference, trackingRef } = action.payload.quoteReferenceDetails;

    if (!quoteReference) throw new Error('quoteReference is not set');
    if (!trackingRef) throw new Error('trackingRef is not set');

    const getQuoteRequest = yield call(() => postQuoteAcceptance(quoteReference, action.payload.quoteAcceptanceRequest, trackingRef!));
    const { data: quoteResponse } = getQuoteRequest;

    yield all([
      put(requestQuoteSuccess(quoteResponse)),
      put(navigateTo('/instant-quote/quote-complete'))  
    ]);
  } catch (error: unknown) {
    yield all([put(setIsLoading(false))]);

    if (!isAxiosError(error)) return;
    // TODO - error needs to be implemented here
    // yield all([put(setError(error))]);
  }
}

export function* requestQuoteSaga() {
  yield takeLatest('quote/requestQuote', requestQuote);
}

export function* getQuoteSaga() {
  yield takeLatest('quote/getQuote', getQuote);
}

export function* getCostBreakdownSaga() {
  yield takeLatest('quote/getCostBreakdown', getCostBreakdownHandlerSaga);
}

export function* acceptQuoteSaga() {
  yield takeLatest('quote/acceptQuote', acceptQuote);
}