import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";

export interface LoadingState {
  isLoading: boolean;
}

const initialState: LoadingState = {
  isLoading: true,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setIsLoading: (state: Draft<typeof initialState>, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const getIsLoading = (state: { loading: LoadingState }) => state.loading.isLoading;

export const { setIsLoading } = loadingSlice.actions;

export default loadingSlice.reducer;