import { PayloadAction } from '@reduxjs/toolkit';
import { navigate } from 'gatsby';
import { takeEvery } from 'redux-saga/effects';

function* navigateTo(action: PayloadAction<string>) {
  yield navigate(action.payload);
}

export function* watchNavigate() {
  yield takeEvery('navigateTo', navigateTo);
}