import React from "react";
import { Provider } from "react-redux";
import { store } from "../store/store";

const storeWrapper = ({ element }) => {
  return (
    <Provider store={store}>
      {element}
    </Provider>
  );
};

export default storeWrapper;