import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import extractQuoteCodeFromReference from '../utils/extractQuoteCodeFromReference/extractQuoteCodeFromReference';
import {
  GetCostBreakdownResponseModel,
  GetQuotationRequestResponseModel,
  QuoteAcceptanceRequestPayload,
  QuoteAcceptanceResponseModel,
  RequestQuotationPayload,
  RequestQuotationResponseModel,
} from './quote.interfaces';

const baseUrl = `${process.env.GATSBY_QUOTE_API_URL}/quotations`;
const headers = {
  'Content-Type': 'application/json',
};

export async function requestQuotation(
  data: RequestQuotationPayload
): Promise<AxiosResponse<RequestQuotationResponseModel>> {
  const url = `${baseUrl}/requests`;

  try {
    const response = await axios.post<RequestQuotationResponseModel>(
      url,
      data,
      {
        headers,
      }
    );

    return response;
  } catch (error: any) {
    throw error?.response?.data;
  }
}

export async function getQuotation(
  quoteReference: string,
  trackingRef?: string,
  trackingReference002?: string
): Promise<AxiosResponse<GetQuotationRequestResponseModel>> {
  const url = `${baseUrl}/${encodeURIComponent(
    encodeURIComponent(quoteReference)
  )}/request`;

  const params = new URLSearchParams();

  if (trackingRef) {
    params.append('trackingRef', trackingRef);
  }

  if (trackingReference002) {
    params.append('trackingReference002', trackingReference002);
  }

  try {
    const response = await axios.get<GetQuotationRequestResponseModel>(url, {
      params,
      headers,
    });

    const previousQuotationReference = extractQuoteCodeFromReference(
      response.data.quoteReference
    );

    if (previousQuotationReference) {
      Cookies.set('previousQuotationReference', previousQuotationReference, {
        expires: 7,
      });
    }

    return response;
  } catch (error) {
    throw error;
  }
}

export async function getCostBreakdown(
  quoteReference: string,
  trackingRef: string,
  trackingReference002?: string
): Promise<AxiosResponse<GetCostBreakdownResponseModel>> {
  const url = `${baseUrl}/${encodeURIComponent(
    encodeURIComponent(quoteReference)
  )}/costBreakdown`;

  const params = new URLSearchParams();

  if (trackingRef) {
    params.append('trackingRef', trackingRef);
  }

  if (trackingReference002) {
    params.append('trackingReference002', trackingReference002);
  }

  try {
    const response = await axios.get<GetCostBreakdownResponseModel>(url, {
      params: {
        trackingRef,
        trackingReference002,
      },
      headers,
    });

    return response;
  } catch (error) {
    throw error;
  }
}

export async function postQuoteAcceptance(
  quoteReference: string,
  quoteInformation: QuoteAcceptanceRequestPayload,
  trackingRef: string,
  trackingReference002?: string
): Promise<AxiosResponse<QuoteAcceptanceResponseModel>> {
  const url = `${baseUrl}/${encodeURIComponent(
    encodeURIComponent(quoteReference)
  )}/acceptance/`;

  const params = new URLSearchParams();

  if (trackingRef) {
    params.append('trackingRef', trackingRef);
  }

  if (trackingReference002) {
    params.append('trackingReference002', trackingReference002);
  }

  try {
    const response = await axios.post<QuoteAcceptanceResponseModel>(
      url,
      quoteInformation,
      {
        headers,
        params,
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
}
