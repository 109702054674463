function extractQuoteCodeFromReference(reference: string): string | null {
  const match = reference.match(/^(.+?)\//);

  if (match) {
    return match[1];
  }

  return null; 
}

export default extractQuoteCodeFromReference;