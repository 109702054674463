import { all } from 'redux-saga/effects';
import { watchNavigate } from './navigation/navigation.saga';
import { acceptQuoteSaga, getCostBreakdownSaga, getQuoteSaga, requestQuoteSaga } from './quote/quote.saga';

export default function* rootSaga() {
  yield all([
    acceptQuoteSaga(),
    getCostBreakdownSaga(),
    getQuoteSaga(),
    requestQuoteSaga(),
    watchNavigate()
  ]);
}
