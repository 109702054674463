import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { GetCostBreakdownResponseModel, GetCostBreakdownRequestModel, GetQuotationRequestModel, GetQuotationRequestResponseModel, RequestQuotationPayload, RequestQuotationResponseModel, CostBreakdownType, ClauseType, Clause, QuoteAcceptanceRequestPayload } from '../../../services/quote.interfaces';

type termsAndConditionsModalContent = 'termsAndConditions' | 'saveForLater';

interface QuoteModalState {
  isQuoteModalOpen: boolean;
  quoteModalContent: termsAndConditionsModalContent | null;
}

export interface QuoteState {
  quoteOverview: RequestQuotationResponseModel | null;
  quoteDetails: GetQuotationRequestResponseModel | null;
  quoteCostBreakdown: GetCostBreakdownResponseModel | null;
  quoteModal: QuoteModalState; 
  error: AxiosError<unknown, any> | null;
}

export interface QuoteError {
  statusCode: number;
  message: string;
  error: string;
}


const initialState: QuoteState = {
  quoteOverview: null,
  quoteDetails: null,
  quoteCostBreakdown: null,
  quoteModal: {
    isQuoteModalOpen: false,
    quoteModalContent: null,
  },  
  error: null,
} as const;

export const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    acceptQuote: (state: Draft<typeof initialState>, action: PayloadAction<{ quoteReferenceDetails: GetQuotationRequestModel, quoteAcceptanceRequest: QuoteAcceptanceRequestPayload}>) => {
    },
    acceptQuoteSuccess: (state: Draft<typeof initialState>, action: PayloadAction<GetQuotationRequestResponseModel>) => {
      state.quoteDetails = action.payload;
    },
    getCostBreakdown: (state: Draft<typeof initialState>, action: PayloadAction<Partial<GetCostBreakdownRequestModel>>) => {
    },
    getCostBreakdownSuccess: (state: Draft<typeof initialState>, action: PayloadAction<GetCostBreakdownResponseModel>) => {
      state.quoteCostBreakdown = action.payload;
    },
    requestQuote: (state: Draft<typeof initialState>, action: PayloadAction<Partial<RequestQuotationPayload>>) => {
    },
    requestQuoteSuccess: (state: Draft<typeof initialState>, action: PayloadAction<RequestQuotationResponseModel>) => {
      state.quoteOverview = action.payload;
    },
    getQuote: (state: Draft<typeof initialState>, action: PayloadAction<Partial<GetQuotationRequestModel>>) => {
      },
    getQuoteSuccess: (state: Draft<typeof initialState>, action: PayloadAction<GetQuotationRequestResponseModel>) => {
      state.quoteDetails = action.payload;
    },
    setError: (state: Draft<typeof initialState>, action: PayloadAction<AxiosError<unknown, any>>) => {
      state.error = action.payload;      
    },
    setModalState: (state: Draft<typeof initialState>, action: PayloadAction<QuoteModalState>) => {
      state.quoteModal = action.payload;
    },
    reset: () => initialState,
  },
});

export const getQuoteState = (state: { quote: QuoteState }) => state.quote;
export const getTransactionType = (state: { quote: QuoteState }) => state.quote.quoteCostBreakdown?.transactionType || state.quote.quoteDetails?.transactionType || null;
export const getQuoteReference = (state: { quote: QuoteState }) => state.quote.quoteDetails?.quoteReference || state.quote.quoteOverview?.quoteReference || state.quote.quoteCostBreakdown?.headlineInformation.quoteReference || null;
export const getQuoteOverviewSelector = (state: { quote: QuoteState }) => state.quote.quoteOverview;
export const getQuoteDetailsSelector = (state: { quote: QuoteState }) => state.quote.quoteDetails;
export const getMoveSpecialistId = (state: { quote: QuoteState }) => state.quote.quoteDetails?.moveSpecialistId || state.quote.quoteOverview?.moveSpecialistId || null;
export const getCustomerDetails = (state: { quote: QuoteState }) => state.quote.quoteDetails?.customerDetails;
export const getQuoteCostBreakdownSelector = (state: { quote: QuoteState }) => state.quote.quoteCostBreakdown;
export const getClausesSelector = (state: { quote: QuoteState }) => state.quote.quoteCostBreakdown?.clauses;
export const getQuoteCostHeadlineInformation = (state: { quote: QuoteState }) => state.quote.quoteCostBreakdown?.headlineInformation;
export const getSavingsNotice = (state: { quote: QuoteState }) => state.quote.quoteCostBreakdown?.costBreakdown.conveyancingBreakdown.savingsNotice;
export const getQuoteModalState = (state: { quote: QuoteState }) => state.quote.quoteModal;

export const getCostBreakdownByType = createSelector(
  [
    getQuoteCostBreakdownSelector,
    (_, costBreakdownType: CostBreakdownType) => costBreakdownType,
  ],
  (quoteCostBreakdown, costBreakdownType) => {
    return quoteCostBreakdown?.costBreakdown?.[costBreakdownType as CostBreakdownType] || null;
  }
);

export const getClausesByType = createSelector(
  [
    getClausesSelector,
    (_, clauseType?: ClauseType) => clauseType,
  ],
  (allClauses, clauseType) => {
    if (!clauseType) return allClauses;

    return allClauses?.filter((clause: Clause) => clause.clauseType === clauseType) || null;
  }
);

export const { acceptQuote, acceptQuoteSuccess, setModalState, getCostBreakdown, getCostBreakdownSuccess, getQuote, getQuoteSuccess, requestQuote, requestQuoteSuccess, reset, setError } = quoteSlice.actions;

export default quoteSlice.reducer;